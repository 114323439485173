import currency from 'currency.js';
import moment from 'moment';
import { PaginationConfig } from 'antd/lib/pagination';
import {
  AgencyType,
  BonusType,
  CardType,
  IdentityReqStatus,
  OrderStatus,
  PostCategory,
  PostStatus,
} from '@app/common';

const initPagination: PaginationConfig = {
  current: 1,
  pageSize: 10,
  total: 0,
  // showTotal: (total, range) => (
  //   <Button type='dashed' className='color-darkcyan' title='Total'>
  //     •《 {total} 》•
  //   </Button>
  // ),
  showSizeChanger: true,
  pageSizeOptions: ['5', '10', '20', '50'],
};

const formatCurrency = (amount: number) => {
  var formatter = new Intl.NumberFormat('vi-VN', {
    style: 'currency',
    currency: 'VND',
  });

  return formatter.format(Number(amount)).replace('₫', '');
};

const formatDateTable = (day: string) => {
  return moment(day).format('DD/MM/YYYY HH:mm');
};

const getColorPostStatus = (status: PostStatus) => {
  const mapping = {
    [PostStatus.Draft]: 'default',
    [PostStatus.Publish]: '#00c853',
  };
  return mapping[status];
};

const getNamePostStatus = (status: PostStatus) => {
  const nameMapping = {
    [PostStatus.Draft]: 'Nháp',
    [PostStatus.Publish]: 'Xuất bản',
  };
  return nameMapping[status];
};

const getColorPostCategory = (status: PostCategory) => {
  const mapping = {
    [PostCategory.Attach]: '#039be5',
    [PostCategory.Noti]: '#ff9800',
    [PostCategory.Video]: '#ff3d00',
  };
  return mapping[status];
};

const getNamePostCategory = (status: PostCategory) => {
  const nameMapping = {
    [PostCategory.Attach]: 'Tài liệu',
    [PostCategory.Noti]: 'Thông báo',
    [PostCategory.Video]: 'Video',
  };
  return nameMapping[status];
};

const getColorOrderStatus = (status: OrderStatus) => {
  const mapping = {
    [OrderStatus.Created]: 'default',
    [OrderStatus.Processing]: '#039be5',
    [OrderStatus.Approved]: '#00c853',
    [OrderStatus.Cancelled]: '#ff9800',
  };
  return mapping[status];
};

const getColorCardByType = (type: CardType) => {
  const colorMapping = {
    [CardType.Type1]: 'blue',
    [CardType.Type2]: 'warning',
    [CardType.Type3]: 'red',
  };
  return colorMapping[type];
};

const getNameOrderStatus = (status: OrderStatus) => {
  const nameMapping = {
    [OrderStatus.Created]: 'Chờ xử lý',
    [OrderStatus.Processing]: 'Đang xử lý',
    [OrderStatus.Approved]: 'Đã hoàn thành',
    [OrderStatus.Cancelled]: 'Đã hủy',
  };
  return nameMapping[status];
};

const getNameExchangeStatus = (status: OrderStatus) => {
  const mapping = {
    [OrderStatus.Created]: 'Chờ xử lý',
    [OrderStatus.Processing]: 'Đang xử lý',
    [OrderStatus.Approved]: 'Đã hoàn thành',
    [OrderStatus.Cancelled]: 'Đã hủy',
  };
  return mapping[status];
};

const getColorExchangeStatus = (status: OrderStatus) => {
  const mapping = {
    [OrderStatus.Created]: 'default',
    [OrderStatus.Processing]: '#039be5',
    [OrderStatus.Approved]: '#00c853',
    [OrderStatus.Cancelled]: '#ff9800',
  };
  return mapping[status];
};

const getColorAgencyTypeStatus = (status: AgencyType) => {
  const mapping = {
    [AgencyType.New]: 'default',
    [AgencyType.Collaborator]: '#00c853',
    [AgencyType.Agency3]: '#00b8d4',
    [AgencyType.Agency2]: '#2962ff',
    [AgencyType.Agency1]: '#aa00ff',
    [AgencyType.MainAgency]: '#d50000',
    [AgencyType.FounderCircle]: '#190D73',
  };
  return mapping[status];
};

const getColorIdentityStatus = (status: IdentityReqStatus) => {
  const mapping = {
    [IdentityReqStatus.Created]: 'default',
    [IdentityReqStatus.Rejected]: 'red',
    [IdentityReqStatus.Approved]: 'green',
  };
  return mapping[status];
};

const getNameIdentityStatus = (status: IdentityReqStatus) => {
  const mapping = {
    [IdentityReqStatus.Created]: 'Mới tạo',
    [IdentityReqStatus.Rejected]: 'Hủy',
    [IdentityReqStatus.Approved]: 'Duyệt',
  };
  return mapping[status];
};

const getNameAgencyType = (status: AgencyType) => {
  const mapping = {
    [AgencyType.New]: 'Mới ĐK',
    [AgencyType.Collaborator]: 'CTV',
    [AgencyType.Agency3]: 'Đại lý 3',
    [AgencyType.Agency2]: 'Đại lý 2',
    [AgencyType.Agency1]: 'Đại lý 1',
    [AgencyType.MainAgency]: 'Tổng đại lý',
    [AgencyType.FounderCircle]: 'Founder Circle',
  };
  return mapping[status];
};

const getBonusTypeName = (type: BonusType) => {
  const types = {
    [BonusType.Department]: 'Thưởng văn phòng',
    [BonusType.Region]: 'Thưởng vùng',
    [BonusType.Order]: 'Thưởng đơn hàng',
    [BonusType.Month]: 'Thưởng tháng',
  };

  return types[type];
};

const enumerateDaysBetweenDates = function (
  startDate: moment.Moment,
  endDate: moment.Moment,
) {
  var dates = [];

  var currDate = moment(startDate).startOf('day');
  var lastDate = moment(endDate).startOf('day');

  while (currDate.add(1, 'days').diff(lastDate) < 0) {
    dates.push(currDate.clone());
  }

  return dates;
};

const enumerateMonthBetweenDates = function (
  startDate: moment.Moment,
  endDate: moment.Moment,
) {
  var dates = [];

  var currDate = moment(startDate).startOf('day');
  var lastDate = moment(endDate).add(1, 'day').startOf('day');

  while (currDate.add(1, 'months').diff(lastDate) <= 0) {
    dates.push(currDate.clone());
  }

  return dates;
};

const getBase64 = (file: any): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export default {
  getBase64,
  initPagination,
  formatCurrency,
  formatDateTable,
  getColorOrderStatus,
  getColorCardByType,
  getColorExchangeStatus,
  getColorAgencyTypeStatus,
  getColorIdentityStatus,
  getNameAgencyType,
  getNameIdentityStatus,
  getNameOrderStatus,
  getNameExchangeStatus,
  getBonusTypeName,
  getColorPostCategory,
  getColorPostStatus,
  getNamePostCategory,
  getNamePostStatus,
  enumerateDaysBetweenDates,
  enumerateMonthBetweenDates,
};
