"use strict";

var _toConsumableArray = require("/root/socialwalk/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/toConsumableArray");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.validBookingInTimeFrames = exports.parseHourToNumber = exports.generateFullFrames = exports.generateFramesBooking = exports.getTreeSelects = exports.getTreeLevels = exports.getTreeLabels = void 0;

var _ = require("lodash");

var getTreeLabels = function getTreeLabels(data) {
  var results = [];
  var cache = {};
  data.forEach(function (node) {
    recurshipLabel(results, cache, undefined, node);
  });
  return results;
};

exports.getTreeLabels = getTreeLabels;

var recurshipLabel = function recurshipLabel(results, cache, parentNode, node) {
  var name = !parentNode ? node.name : "".concat(cache[parentNode.id], "/").concat(node.name);
  cache[node.id] = name;
  results.push({
    id: node.id,
    dps: node.dps,
    name: name
  });

  if (_.isEmpty(node.children)) {
    return;
  }

  node.children.forEach(function (cNode) {
    recurshipLabel(results, cache, node, cNode);
  });
};

var getTreeLevels = function getTreeLevels(trees) {
  var cache = {};
  recurshipLevel(cache, {
    id: 0,
    children: trees
  });
  return cache;
};

exports.getTreeLevels = getTreeLevels;

var recurshipLevel = function recurshipLevel(cache, node) {
  if (_.isEmpty(node.children)) {
    cache[node.id] = 1;
    return;
  }

  var max = 0;
  node.children.forEach(function (cNode) {
    recurshipLevel(cache, cNode);
    max = _.max([max, cache[cNode.id] + 1]);
  });
  cache[node.id] = max;
};

var getTreeSelects = function getTreeSelects(trees) {
  recurshipSelect({
    id: 0,
    key: 0,
    children: trees
  });
  return trees;
};

exports.getTreeSelects = getTreeSelects;

var recurshipSelect = function recurshipSelect(node) {
  if (_.isEmpty(node.children)) {
    node.key = node.id;
    return;
  }

  node.children.forEach(function (cNode) {
    recurshipSelect(cNode);
  });
  node.key = node.id;
};

var generateFramesBooking = function generateFramesBooking(allFrames, startFrame, duration) {
  if (_.isEmpty(allFrames)) return [];
  if (!startFrame || duration === 0) return [];
  var array = [];

  for (var index = 0; index < 23; index++) {
    var hour = getFullHour(index);
    array = [].concat(_toConsumableArray(array), [parseInt("".concat(hour, "00")), parseInt("".concat(hour, "30"))]);
  }

  var _startTime = _.parseInt(startFrame.replace(":", ""));

  var others = duration / 30 * 50;
  var data = allFrames.map(function (frames) {
    var ints = frames.map(function (frame) {
      return _.parseInt(frame.replace(":", ""));
    });

    if (!_startTime) {
      _startTime = ints[0];
    }

    var results = array.filter(function (item) {
      var isTrue = item < ints[1] && item >= ints[0] && item >= _startTime && _startTime + others > item;
      return isTrue;
    });
    if (_.isEmpty(results)) return [];
    others = _startTime + others - ints[1];
    _startTime = null;
    return results;
  });
  if (others > 0) return [];
  return _.flatten(data).map(function (i) {
    var time = i >= 1000 ? i.toString() : "0".concat(i);
    return time.substr(0, 2) + ":" + time.substr(2);
  });
};

exports.generateFramesBooking = generateFramesBooking;

var generateFullFrames = function generateFullFrames(allFrames, booked, busy, free) {
  if (_.isEmpty(allFrames)) return [];
  var array = [];

  for (var index = 0; index < 23; index++) {
    var hour = getFullHour(index);
    array = [].concat(_toConsumableArray(array), [parseInt("".concat(hour, "00")), parseInt("".concat(hour, "30"))]);
  }

  var books = (booked || []).map(exports.parseHourToNumber);
  var busies = (busy || []).map(exports.parseHourToNumber);
  var frees = (free || []).map(exports.parseHourToNumber);
  var cannot = [].concat(_toConsumableArray(books), _toConsumableArray(busies));
  var data = allFrames.map(function (frames) {
    var ints = frames.map(exports.parseHourToNumber);
    var results = array.filter(function (item) {
      return ints[0] <= item && item < ints[1];
    }).map(function (item) {
      var result = {
        time: item,
        isAvailable: true
      };

      if (cannot.includes(item)) {
        result.isAvailable = false;
      }

      return result;
    });
    return results;
  });
  return _.flatten(data).map(function (item) {
    var isAvailable = item.isAvailable,
        time = item.time;
    var ftime = time >= 1000 ? time.toString() : "0".concat(time);
    return {
      isAvailable: isAvailable,
      time: ftime.substr(0, 2) + ":" + ftime.substr(2)
    };
  });
};

exports.generateFullFrames = generateFullFrames;

var getFullHour = function getFullHour(hour) {
  if (hour > 9) return hour.toString();
  return "0".concat(hour);
};

var parseHourToNumber = function parseHourToNumber(time) {
  return _.parseInt(time.replace(":", ""));
};

exports.parseHourToNumber = parseHourToNumber;

var validBookingInTimeFrames = function validBookingInTimeFrames(timeFrames, bFrames) {
  var parserTF = _(timeFrames).flattenDeep().map(function (i) {
    return (0, exports.parseHourToNumber)(i);
  });

  var maxTF = parserTF.max();
  var minTF = parserTF.min();

  var parserB = _(bFrames).map(function (i) {
    return (0, exports.parseHourToNumber)(i);
  });

  var maxB = parserB.max();
  var minB = parserB.min();
  if (!maxB || !minB || !minTF || !maxTF) return false;
  return minB >= minTF && maxB <= maxTF;
};

exports.validBookingInTimeFrames = validBookingInTimeFrames;