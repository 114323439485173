import axios from 'axios/index';
import { requestHelper } from 'helpers';
import { userServices } from 'services';

export const BASE_API_URL: string = `${process.env.REACT_APP_BASE_API_URL ||
  'http://localhost:4000'}/api`;

export const BASE_CRUD_URL: string = `${process.env.REACT_APP_BASE_API_URL ||
  'http://localhost:4000'}/crud`;

axios.interceptors.response.use(
  response => response,
  error => {
    requestHelper.handleRequestError(error);
    return Promise.reject(error);
  },
);

const getAuthorization = () => `Bearer ${userServices.getAccessToken()}`;

const userClient = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    Authorization: getAuthorization(),
  },
  withCredentials: false,
});

userClient.interceptors.response.use(
  response => response,
  error => {
    requestHelper.handleRequestError(error);
    return Promise.reject(error);
  },
);

const crudClient = axios.create({
  baseURL: BASE_CRUD_URL,
  headers: {
    Authorization: getAuthorization(),
  },
  withCredentials: false,
});

crudClient.interceptors.response.use(
  response => response,
  error => {
    requestHelper.handleRequestError(error);
    return Promise.reject(error);
  },
);

export default {
  userClient,
  crudClient,
};
