export default {
  KEY_CURRENT_PERMISSIONS: 'currentPermissions' as string,
  KEY_CURRENT_USER: 'currentUser' as string,
  KEY_CARD: 'cards',
  KEY_CFG: 'cfgs',
  KEY_TRIAL_CARD: 'trialcards',
  api: {
    USER_DETAIL: '/me' as string,
  },
};
