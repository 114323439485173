"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PolicyFieldMode = exports.NotificationStatus = exports.BookingStatus = exports.AbsentType = exports.DayInWeek = exports.CouponStatus = exports.CouponType = exports.NotificationType = exports.Sex = exports.Role = exports.QueueProcessorHandler = exports.QueueProcessor = exports.LoginType = exports.CONSTANTS_EXAMPLE = void 0;
exports.CONSTANTS_EXAMPLE = "example";
var LoginType;

(function (LoginType) {
  LoginType["BASIC"] = "basic";
  LoginType["GOOGLE"] = "google";
  LoginType["FACEBOOK"] = "facebook";
  LoginType["APPLE"] = "apple";
})(LoginType = exports.LoginType || (exports.LoginType = {}));

var QueueProcessor;

(function (QueueProcessor) {
  QueueProcessor["EMAIL"] = "email";
})(QueueProcessor = exports.QueueProcessor || (exports.QueueProcessor = {}));

var QueueProcessorHandler;

(function (QueueProcessorHandler) {
  QueueProcessorHandler["SEND_EMAIL"] = "send-email";
})(QueueProcessorHandler = exports.QueueProcessorHandler || (exports.QueueProcessorHandler = {}));

var Role;

(function (Role) {
  Role["Admin"] = "Admin";
  Role["Manager"] = "Manager";
  Role["Member"] = "Member";
})(Role = exports.Role || (exports.Role = {}));

var Sex;

(function (Sex) {
  Sex["Male"] = "m";
  Sex["Female"] = "f";
  Sex["They"] = "t";
  Sex["Others"] = "others";
})(Sex = exports.Sex || (exports.Sex = {}));

var NotificationType;

(function (NotificationType) {
  NotificationType["Basic"] = "Basic";
  NotificationType["EarnCoin"] = "EarnCoin";
  NotificationType["DonateCoin"] = "DonateCoin";
})(NotificationType = exports.NotificationType || (exports.NotificationType = {}));

var CouponType;

(function (CouponType) {
  CouponType["ForUser"] = "for-user";
  CouponType["ForAllUser"] = "for-all-user";
  CouponType["Stamp"] = "stamp";
  CouponType["Invite"] = "invite";
  CouponType["Register"] = "register";
  CouponType["BeforeRegister"] = "before-register";
  CouponType["LastActive"] = "last-active";
})(CouponType = exports.CouponType || (exports.CouponType = {}));

var CouponStatus;

(function (CouponStatus) {
  CouponStatus["Used"] = "used";
  CouponStatus["Assign"] = "assign";
})(CouponStatus = exports.CouponStatus || (exports.CouponStatus = {}));

var DayInWeek;

(function (DayInWeek) {
  DayInWeek["Monday"] = "monday";
  DayInWeek["Tuesday"] = "tuesday";
  DayInWeek["Wednesday"] = "wednesday";
  DayInWeek["Thursday"] = "thursday";
  DayInWeek["Friday"] = "friday";
  DayInWeek["Saturday"] = "saturday";
  DayInWeek["Sunday"] = "sunday";
})(DayInWeek = exports.DayInWeek || (exports.DayInWeek = {}));

var AbsentType;

(function (AbsentType) {
  AbsentType["Online"] = "online";
  AbsentType["Offline"] = "offline";
})(AbsentType = exports.AbsentType || (exports.AbsentType = {}));

var BookingStatus;

(function (BookingStatus) {
  BookingStatus["Booking"] = "booking";
  BookingStatus["Cancel"] = "cancel";
  BookingStatus["Visited"] = "visited";
})(BookingStatus = exports.BookingStatus || (exports.BookingStatus = {}));

var NotificationStatus;

(function (NotificationStatus) {
  NotificationStatus["Unseen"] = "unseen";
  NotificationStatus["Seen"] = "seen";
})(NotificationStatus = exports.NotificationStatus || (exports.NotificationStatus = {}));

var PolicyFieldMode;

(function (PolicyFieldMode) {
  PolicyFieldMode["PUBLIC"] = "public";
  PolicyFieldMode["PRIVATE"] = "private";
})(PolicyFieldMode = exports.PolicyFieldMode || (exports.PolicyFieldMode = {}));